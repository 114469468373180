import { useState } from 'react';

import mec_name from './mec_name.js'

import './lhd_f.css';
function Lhd_f_god(props) {
    let s_lhd = props.lhd
    let w_lhds = []
    for (let i = 0; i < props.lhds.length; i++) {
        if (props.lhds[i].sotr == s_lhd.sotr && props.lhds[i].god == s_lhd.god) {
            w_lhds.push(props.lhds[i])
        }
    }
    let w_lscs = []; w_lscs.length = 0
    for (let i = 0; i < props.lscs.length; i++) {
        let x_lscs = props.lscs[i]
        if (x_lscs.sotr === s_lhd.sotr && x_lscs.god == s_lhd.god)
            w_lscs.push(x_lscs)

    }



    w_lscs.sort(function (a, b) {
        if ((a.god - b.god) > 0 || (a.god == b.god && (a.mec - b.mec) > 0))
            return 1;
        else
            if (a.god == b.god && a.mec == b.mec)
                if ((a.godb - b.godb) > 0 || (a.godb == b.godb && (a.mecb - b.mecb) > 0))
                    return 1;
                else
                    if (a.godb == b.godb && a.mecb == b.mecb)
                        if (a.sq > b.sq)
                            return 1;
                        else
                            return -1;
                    else
                        return -1
            else
                return -1

    })
    let wg = 0; let wm = 0; let wkopb = 0;
    let w_str = []; w_str.length = 0
    for (let i = 0; i < w_lscs.length; i++) {
        let x_lscs = w_lscs[i]
        if (wm !== x_lscs.mec || wg !== x_lscs.god) {
            wm = x_lscs.mec
            wg = x_lscs.god
            wkopb = 0
            for (let x = 0; x < w_lhds.length; x++) {
                if (w_lhds[x].mec === wm && w_lhds[x].god === wg) {
                    wkopb = w_lhds[x].kopb
                    break
                }
            }
            w_str.push({ "sq": " ", "name": mec_name(wm) + ' ' + wg + ' Сальдо ' + wkopb })
        }



        w_str.push(x_lscs)
    }


    let srows = w_str.map((ar, index) => {

        if (ar.sq == ' ') {
            return (
                <tr className="tr_lsc_mec">
                    <td >{ar.sq}</td>
                    <td colspan="7">{ar.name}</td>

                </tr>
            )
        }
        else
            if ((ar.god - ar.godb)<0 || (ar.god == ar.godb && (ar.mec - ar.mecb)<0)) {
                return (
                    <tr className="tr_lsc">
                        <td className="tr_lsc_next">{ar.sq}</td>
                        <td >{ar.name}</td>
                        <td className="sumr">{ar.nach}</td>
                        <td className="sumr">{ar.uder}</td>

                    </tr>
                )
            }
            else
                if ((ar.god - ar.godb)>0 || (ar.god == ar.godb && (ar.mec - ar.mecb)>0)) {
                    return (
                        <tr className="tr_lsc">
                            <td className="tr_lsc_pred">{ar.sq}</td>
                            <td >{ar.name}</td>
                            <td className="sumr">{ar.nach}</td>
                            <td className="sumr">{ar.uder}</td>

                        </tr>
                    )
                }
                else {
                    return (
                        <tr className="tr_lsc">
                            <td >{ar.sq}</td>
                            <td >{ar.name}</td>
                            <td className="sumr">{ar.nach}</td>
                            <td className="sumr">{ar.uder}</td>

                        </tr>
                    )
                }
    })

    return (
        <div>
            <table >
                <thead>
                    <tr>
                        <th className="th_center " colSpan="8">
                            <span >Таб {s_lhd.sotr + ' ' + s_lhd.fio}   </span>
                        </th>
                    </tr>

                    <tr>
                        <th className="th_center" colSpan="8">
                            <span> {s_lhd.nprof + ' ' + s_lhd.nmag}  </span>
                        </th>


                    </tr>
                    <tr>
                        <th className="th_center tr_lsc_mec" colSpan="4">
                            <button onClick={prior_lhd}> Назад </button>
                            <span > {s_lhd.god}  </span>
                            <button onClick={next_lhd}> Вперед</button>
                        </th>



                    </tr>
                    <tr>
                        <th className="sq_grs"> Код</th>
                        <th className="th_center ngrs"> Название</th>
                        <th className="sumr"> Начислено </th>
                        <th className="sumr"> Удержано </th>


                    </tr>
                </thead>
                <tbody>
                    {srows}
                </tbody>
            </table>


        </div>

    )
    // //+ {s_lhd.nach}  - {s_lhd.uder}
    function call_lhd(wsotr, wgod) {

        for (let i = 0; i < props.lhds.length; i++) {
            let w_lhd = props.lhds[i]
            if (w_lhd.sotr == wsotr && w_lhd.god == wgod) {

                props.Setslhd(w_lhd)
                break
            }
        }
    }
    function prior_lhd() {
        let wgod = s_lhd.god - 1
        call_lhd(s_lhd.sotr, wgod)


    }
    function next_lhd() {

        let wgod = s_lhd.god
        wgod++

        call_lhd(s_lhd.sotr, wgod)

    }
}



export default Lhd_f_god;