import logo from './logo.svg';
import './App.css';
import Lhds_t from './Lhds_t.js';

import { useState } from 'react';
import List_sotr from './List_sotr.js';
import List_grs from './List_grs.js';
import mec_name from './mec_name.js'
import grssu2 from './grssu2.json';
import lscsu2 from './lscsu2.json';
import sotrsu2 from './sotrsu2.json';
import profsu2 from './profsu2.json';
import lhdsu2 from './lhdsu2.json';

import grssu3 from './grssu3.json';
import lscsu3 from './lscsu3.json';
import sotrsu3 from './sotrsu3.json';
import profsu3 from './profsu3.json';
import lhdsu3 from './lhdsu3.json';

function App() {
  const [sgrs, Setsgrs] = useState(0)
  const [sbase, Setsbase] = useState()
  const [ssotr, Setssotr] = useState(0)
  let snbase = 'xxxxxxxxxx';
  let sotrs = []; let grss = []; let profs = []; let lhds = []; let lscs = [];
  if (sbase == "su2") {
    grss = Array.from(grssu2)
    lscs = Array.from(lscsu2)
    lhds = Array.from(lhdsu2)
    profs = Array.from(profsu2)
    sotrs = Array.from(sotrsu2)
  }
  else if (sbase == "su3") {
    grss = Array.from(grssu3)
    lscs = Array.from(lscsu3)
    lhds = Array.from(lhdsu3)
    profs = Array.from(profsu3)
    sotrs = Array.from(sotrsu3)
  }

  let x = 0

  for (let i = 0; i < lhds.length; i++) { // выведет 0, затем 1, затем 2
    for (let z = 0; z < sotrs.length; z++) { // выведет 0, затем 1, затем 2
      if (sotrs[z].kod === lhds[i].sotr) {
        lhds[i].fio = sotrs[z].fio
        break
      }
    }

    for (let z = 0; z < profs.length; z++) { // выведет 0, затем 1, затем 2
      if (profs[z].kod === lhds[i].prof) {
        lhds[i].nprof = profs[z].name
        break
      }
    }
  }


  for (let i = 0; i < lscs.length; i++) { // выведет 0, затем 1, затем 2
    for (let z = 0; z < grss.length; z++) { // выведет 0, затем 1, затем 2
      if (grss[z].kod === lscs[i].grs) {
        lscs[i].name = grss[z].name
        break
      }
    }
    if (lscs[i].ndog && lscs[i].nach) {
      lscs[i].name += ' ' + lscs[i].ndog + ' от ' + lscs[i].ddog
    }
    if (lscs[i].bdog && lscs[i].nach) {
      let w_bdog = lscs[i].bdog.split('/')
      let w_edog = lscs[i].edog.split('/')
      if (w_bdog[1] == w_edog[1])
        lscs[i].name += ' c ' + w_bdog[0] + ' по ' + w_edog[0] + ' ' + mec_name(w_bdog[1])
    }

    if (lscs[i].nach=="" && lscs[i].uder=="" && lscs[i].sumr!=="")
      lscs[i].name += ' ' + lscs[i].sumr +' руб'
    if (lscs[i].rab)
      lscs[i].name += ' ' + lscs[i].rab
    if (lscs[i].rabt)
      lscs[i].name += ' ' + lscs[i].rabt

    if (lscs[i].mec !== lscs[i].mecb)
      lscs[i].name += '///' + mec_name(lscs[i].mecb) + ' ' + lscs[i].godb

  }

  
  return (
    <div className="App" >


      <label>    Пароль <input value={sbase} onChange={e => { Setsbase(e.target.value) }} /> {" "}</label>
      <div style={{ "display": "flex" }}>
        <List_sotr Setssotr={Setssotr} sotr={sotrs} />
        <List_grs Setsgrs={Setsgrs} grs={grss} />
      </div>
      <Lhds_t sotr={ssotr} grs={sgrs} Setssotr={Setssotr} lscs={lscs} lhds={lhds} />
    </div>
  );
}

export default App;

// var request = new XMLHttpRequest;

// request.open('GET', 'us_quantity.txt', true);

// request.onload = function () {
//     console.log(request.responseText);
// };

// request.send(null);
