


function lhd_grss(props) {

    const slhd = props.lhd

  
    let w_lscs = props.lscs.filter((item) => {
        if (item.sotr == slhd.sotr && item.god == slhd.god && item.mec == slhd.mec)
            return true
        else
            return false
    })

    let srows = w_lscs.map((ar) => {
        return ar.grs
    })
    return srows
}
export default lhd_grss;