
import React from 'react'
 

function List_sotr(props) {
    let w_sotr = Array.from(props.sotr)

    w_sotr.sort(function (a, b) {
        if (a.fio > b.fio) return 1; else return -1;
    })

   

    let x_sotrs = w_sotr.map((ar) => {
        return (
            <option key={ar.kod} value={ar.kod}>{ar.fio}</option>
        )
    })
    
    return (
                <div  >
                    <select id="list_sotr" onChange={(e) => props.Setssotr(e.target.value)}>
                    <option key={"0"} value={"0"}>Все сотрудники</option>
                        {x_sotrs}
                    </select>
                </div>
    )

}
export default List_sotr;
 