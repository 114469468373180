function mec_name(pmec) {
    if (pmec==1) return ("Январь")
        else
    if (pmec==2) return ("Февраль")
        else
    if (pmec==3) return ("Март")
        else
    if (pmec==4) return ("Апрель")
        else
    if (pmec==5) return ("Май")
        else
    if (pmec==6) return ("Июнь")
        else
    if (pmec==7) return ("Июль")
        else
    if (pmec==8) return ("Август")
        else
    if (pmec==9) return ("Сентябрь")
        else
    if (pmec==10) return ("Октябрь")
        else
    if (pmec==11) return ("Ноябрь")
        else
    if (pmec==12) return ("Декабрь")

}
export default mec_name;