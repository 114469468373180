import React from 'react'

function List_grs(props) {
    let w_grss=Array.from(props.grs)
    

    
    w_grss.sort(function (a, b) {
            if (a.sq > b.sq) 
                return 1; 
            else 
                return -1;
    })
    


    let s_grss = w_grss.map(grs => {
      
            return  <option key={grs.kod} value={grs.kod}>{grs.sq + ' ' + grs.name}</option>

    })
    
    return (
            <div >
                <select id="list_grs" onChange={(e) => {props.Setsgrs(e.target.value)}}>
                
                <option key="0" value="0" >Все операции</option>
                    {s_grss}
                </select>
            </div>
    )

}

export default List_grs;