import React from 'react'
import { useState } from 'react';

import lhd_grss from './lhd_grss.js';
 
import Lhd_f_god from './Lhd_f_god.js';


import './lhds_t.css';

function Lhds_t(props) {

  const [slhd, Setslhd] = useState({})
  const [nsort, Setnsort] = useState(1)
  const [sfio, Setsfio] = useState(" ")
  const [snprof, Setsnprof] = useState(" ")
  const [sgod, Setsgod] = useState(2024)
  const [smec, Setsmec] = useState(7)
  const [smag, Setsmag] = useState(" ")

  let w_sotr;


  let w_lhds = props.lhds.filter(function (ar) {

    if ((sgod == 0 || sgod == ar.god)
      && (smec == 0 || smec == ar.mec)
      && (props.sotr == 0 || props.sotr == ar.sotr)
      && (smag === " " || ar.nmag.includes(smag))
      && (snprof === " " || ar.nprof.includes(snprof))
      && (sfio === " " || ar.fio.includes(sfio))
      && (props.grs == 0 || lhd_grss(ar).includes(props.grs))   //  возвращает массив операций расч листка
    )
      return true
    else
      return false
  })

  if (nsort) {
    w_lhds.sort(function (a, b) {
      if (nsort === 1)
        if ((a.sotr - b.sotr) > 0) return 1; else return -1;
      else
        if (nsort === 2)
          if (a.fio > b.fio) return 1; else return -1;
        else
          if (nsort === 3)
            if (a.nprof > b.nprof) return 1; else return -1;
          else
            if (nsort === 4)
              if (a.nmag > b.nmaag) return 1; else return -1;
            else
              if (nsort === 5)
                if ((a.oklad - b.oklad) > 0) return 1; else return -1;


    });
  }

  ////onClick={() => props.Setsotr(ar.sotr)}>

  let srows = w_lhds.map((ar) => {
    if (ar.d_otp || ar.s_otp)
      return (
        <tr id={ar.sotr + '@' + ar.god + '@' + ar.mec} className="tr_lhds tr_otp" onClick={init_lhd}>
          <td className="tab">{ar.sotr}  </td>
          <td className="fio">{ar.fio}</td>
          <td className="nprof">{ar.nprof}  </td>
          <td className="nmag">{ar.nmag}  </td>

          <td className="god">{ar.god}</td>
          <td className="mec">{ar.mec}</td>
        </tr>
      )
    else
      return (
        <tr id={ar.sotr + '@' + ar.god + '@' + ar.mec} className="tr_lhds" onClick={init_lhd}>
          <td className="tab">{ar.sotr}  </td>
          <td className="fio">{ar.fio}</td>
          <td className="nprof">{ar.nprof}  </td>
          <td className="nmag">{ar.nmag}  </td>

          <td className="god">{ar.god}</td>
          <td className="mec">{ar.mec}</td>
        </tr>
      )
  })

  return (
    <div style={{ "display": "flex" }}>
      <div className="lhds_t" id="lhds_t" >
        <table  >
          <thead>

            <tr >
              <th className="tab" onClick={() => Setnsort(1)}><div>Таб №</div> <div><input className="tab" value={w_sotr} onChange={e => { props.Setssotr(e.target.value) }} /> </div></th>
              <th onClick={() => Setnsort(2)}>Фамилия И.О <div><input display="block" className="ffio" value={sfio} onChange={e => { Setsfio(e.target.value) }} /></div> </th>
              <th onClick={() => Setnsort(3)} >Должность <div><input className="nprof" value={snprof} onChange={e => { Setsnprof(e.target.value) }} /></div> </th>
              <th onClick={() => Setnsort(4)} >Подр <div><input className="nmag" value={smag} onChange={e => { Setsmag(e.target.value) }} /></div> </th>

              <th onClick={() => Setnsort(6)}>Год  <div><input className="god" value={sgod} onChange={e => { Setsgod(e.target.value) }} /></div></th>
              <th onClick={() => Setnsort(7)}>Мес <div><input className="mec" value={smec} onChange={e => { Setsmec(e.target.value) }} /></div> </th>
            </tr>
          </thead>
          <tbody >
            {srows}
          </tbody>
        </table>
      </div >
      <div className="lhd_f" id="lhd_f" >
        <Lhd_f_god lhd={slhd} Setslhd={Setslhd} lhds={props.lhds} lscs={props.lscs} />
      </div>
    </div>
  )
  function init_lhd(e) {
    const pid = e.currentTarget.id
    //const ( psotr,pgod,pmec ) = pid.split("@")
    const psotr = pid.split("@")[0]   //sotr
    const pgod = pid.split("@")[1]    //god
    const pmec = pid.split("@")[2]    //mec
    if (psotr == slhd.sotr)
      return

    for (let i = 0; i < w_lhds.length; i++) {
      if (w_lhds[i].sotr == psotr && w_lhds[i].god == pgod && w_lhds[i].mec == pmec) {
        Setslhd(w_lhds[i])
        break
      }
    }
  }

}
export default Lhds_t;